
import { Component, Vue } from 'vue-property-decorator';
import BaseJumbotron from '@thxnetwork/public/components/BaseJumbotron.vue';
import BaseFeatures from '@thxnetwork/public/components/BaseFeatures.vue';
import BasePartners from '@thxnetwork/public/components/BasePartners.vue';
import BaseContact from '@thxnetwork/public/components/BaseContact.vue';
import { BButton, BModal } from 'bootstrap-vue';
import { PRICING_TAGS, TWITTER_TAGS, LINKS, TITLES } from '@thxnetwork/public/utils/constants';

@Component({
    metaInfo: {
        title: PRICING_TAGS.TITLE,
        meta: [
            { name: 'title', content: PRICING_TAGS.TITLE },
            { name: 'description', content: PRICING_TAGS.DESCRIPTION },
            { name: 'keywords', content: PRICING_TAGS.KEYWORDS },
            { name: 'twitter:card', content: TWITTER_TAGS.TWITTER_CARD },
            { name: 'twitter:site', content: TWITTER_TAGS.TWITTER_SITE },
            { name: 'twitter:creator', content: TWITTER_TAGS.TWITTER_CREATOR },
            { name: 'twitter:title', content: PRICING_TAGS.TITLE },
            { name: 'twitter:description', content: PRICING_TAGS.DESCRIPTION },
            { name: 'twitter:image:alt', content: PRICING_TAGS.TITLE },
            { property: 'og:title', content: PRICING_TAGS.TITLE },
            { property: 'og:description', content: PRICING_TAGS.DESCRIPTION },
            { property: 'og:type', content: PRICING_TAGS.OG_TYPE },
            { property: 'og:site_name', content: PRICING_TAGS.OG_SITE_NAME },
            { property: 'og:url', content: PRICING_TAGS.OG_URL },
        ],
        link: [{ rel: 'canonical', href: LINKS.PRICING }],
    },
    components: {
        'b-button': BButton,
        'b-modal': BModal,
        'base-jumbotron': BaseJumbotron,
        'base-contact': BaseContact,
        'base-partners': BasePartners,
        'base-features': BaseFeatures,
    },
})
export default class Home extends Vue {
    perYear = true;
    TITLES = TITLES;
}
